import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes, useNavigate, matchPath  } from 'react-router-dom';
import { RoutesContext } from '../contexts/RoutesContext';
import { LanguageContext } from "../contexts/LanguageContext";
import { UserContext } from "../contexts/UserContext"; // Assumindo que o UserContext foi criado e fornece o estado de autenticação
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';
import Loading from '../components/layouts/Loading';
import { useWebSocket } from '../contexts/WebSocketContext';

const AppRoutes = () => {
    const { routes, getPathRoute } = useContext(RoutesContext);
    const { txt } = useContext(LanguageContext);
    const { isLogged,updateLoginTime } = useContext(UserContext);
    const [loadedComponents, setLoadedComponents] = useState({});
    const navigate = useNavigate();
    const { sendMessageOnly } = useWebSocket();

    

    useEffect(() => {
        const loadComponents = async () => {
            const loadedComps = {};

            for (const { component } of routes) {
                if (!loadedComps[component]) {
                    const Comp = React.lazy(() => import(`../components/${component}`));
                    loadedComps[component] = Comp;
                }
            }

            setLoadedComponents(loadedComps);
        };

        loadComponents();
    }, [routes]);

    useEffect(() => {
        const handleRouteChange = (location) => {
            const currentRoute = routes.find(route => 
                matchPath({ path: getPathRoute(route.alias), exact: true }, location.pathname)
            );
            if (currentRoute) {
                sendMessageOnly('route','updateUrl',{url:location.pathname})
                const pageTitle = currentRoute.title ? `${currentRoute.title} - FantasyDraft` : "FantasyDraft";
                document.title = pageTitle;

                if (currentRoute.only_logged && !isLogged()) {
                    navigate(getPathRoute('login'));
                }

                if ((currentRoute.path === getPathRoute('login') || currentRoute.path === getPathRoute('register')) && isLogged()) {
                    navigate(getPathRoute('dashboard'));
                }
                if (isLogged()) {
                    updateLoginTime();
                }
            }
        };

        handleRouteChange(window.location);

        const unlisten = () => {
            window.removeEventListener('popstate', () => handleRouteChange(window.location));
        };
        window.addEventListener('popstate', () => handleRouteChange(window.location));

        return () => {
            unlisten();
        };
    }, [routes, txt, navigate,getPathRoute,isLogged,updateLoginTime]);

    return (
        <div id="container">
            <Header />
            <Routes>
                {routes.map(({ path, component, title, only_logged }, index) => {
                    const Component = loadedComponents[component];
                    if (!Component) return null;
                    return (
                        <Route
                            key={index}
                            path={txt(path)}
                            element={
                                <React.Suspense fallback={<Loading />}>
                                    <Component />
                                </React.Suspense>
                            }
                        />
                    );
                })}
            </Routes>
            <Footer />
        </div>
    );
};

export default AppRoutes;
