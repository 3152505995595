import axios from "axios";
import { encryptData, decryptData } from "./utils/Cryptography";


// Configurações globais do Axios
axios.defaults.withCredentials = true;

// Função para obter o token CSRF
const getCsrfToken = async () => {
  try {
    await axios.get(`${process.env.REACT_APP_API_SANCTUM}/csrf-cookie`);
  } catch (error) {}
};

// Criando instância do Axios
const Api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptando requisições para criptografar os dados
Api.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem("user");
    if(user){
      const {token} = decryptData(user);
      if(token){
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    if (config.data) {
      config.data = { payload: encryptData(JSON.stringify(config.data)) };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptando respostas para descriptografar os dados
Api.interceptors.response.use(
  (response) => {
    if (response.data) {
      try {
        response.data = decryptData(response.data.payload);
      } catch (error) {}
    }
    return response;
  },
  (error) => {
    if (error.response) {
      try {
        error.response.data = decryptData(error.response.data.payload);
      } catch (decryptError) {}
    }
    return Promise.reject(error);
  }
);

// Inicializa o token CSRF
getCsrfToken();

export default Api;
